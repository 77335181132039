<template>
  <div class="box" :class="color ? `color-${color}` : ''">
    <svg
      width="200"
      height="100"
      viewBox="0 0 200 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute right-0 top-0 bottom-0 opacity-50 h-full"
      :class="patternClass"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="300"
        height="150"
      >
        <rect width="300" height="150" fill="currentColor" />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="210"
          y="34"
          width="200"
          height="100"
          rx="50"
          transform="rotate(90 210 34)"
          fill="currentColor"
          fill-opacity="0.3"
        />
        <rect
          x="126"
          y="-24"
          width="200"
          height="100"
          rx="50"
          fill="currentColor"
          fill-opacity="0.3"
        />
        <rect
          x="181.5"
          y="-116.5"
          width="199"
          height="99"
          rx="49.5"
          transform="rotate(90 181.5 -116.5)"
          stroke="currentColor"
          stroke-opacity="0.3"
        />
      </g>
    </svg>
    <div class="box-title">{{ title }}</div>
    <div class="box-content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
    content: {
      type: [String, Number],
      default: undefined,
    },
    patternClass: {
      type: [String, Object, Array],
      default: undefined,
    },
    patternOpacity: {
      type: [String, Number],
      default: () => 50,
    },
    color: {
      type: [String],
      default: () => '',
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  border-radius: .25rem;

  &.color {
    &-primary {
      background: var(--kit-color-primary) !important;
      color: #fff;
    }
    &-indigo {
      background: #7F9CF5 !important;
      color: #fff;
    }
    &-orange {
      background: #F6AD55 !important;
      color: #fff;
    }
    &-pink {
      background: #F687B3 !important;
      color: #fff;
    }
    &-red {
      background: #F56565 !important;
      color: #fff;
    }
    &-teal {
      background: #4FD1C5 !important;
      color: #fff;
    }
    &-yellow {
      background: #F6E05E !important;
      color: #686241;

      svg {
        color: rgb(255, 255, 255);
      }
    }
    &-gray {
      background: #E2E8F0 !important;
      color: #333;

      svg {
        color: #bebebe;
      }
    }
    &-green {
      background: #41b883 !important;
      color: #fff;
    }
  }
}

.box-title {
  // font-size: .9rem;
}

.box-content {
  font-size: 1.5rem;
  font-weight: bold;
}

svg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  opacity: .5;
}
</style>
