<template>
  <div class="media-kit">
    <div class="text-bold mb-3" style="font-size: 24px">
      Media Kit
    </div>
    <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gutter-xs-1 gutter-md-2 gutter-lg-2 mx-n2">
      <div class="col px-2" v-for="(media, index) in mediaKit" :key="index" style="cursor: pointer; border-radius: 50px">
        <div class="bg-white mb-4" @click.prevent="() => handleClickMediaKit(media.ext_url)">
          <div class="image text-center" :style="{ color: iconMedia(media.type).color }">
            <i :class="['fa', iconMedia(media.type).icon]" />
          </div>
          <div class="px-2 py-2">
            <div class="pt-1 text-center" style="height: 65px;">
              <strong> {{ media.description }} </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'media-kit',
  props: {
    mediaKit: {
      type: Array,
      defaultValue: [],
    },
  },
  methods: {
    iconMedia (param) {
      switch (param) {
        case 'pdf':
          return {
            icon: 'fa-file-pdf-o',
            color: '#F56565',
          }

        case 'archive':
          return {
            icon: 'fa-file-archive-o',
            color: '#7F9CF5',
          }

        case 'sound':
          return {
            icon: 'fa-file-sound-o',
            color: '#F6E05E',
          }

        case 'spreadsheet':
          return {
            icon: 'fa-file-excel-o',
            color: '#41b883',
          }

        case 'image':
          return {
            icon: 'fa-file-image-o',
            color: '#E2E8F0',
          }

        case 'video':
          return {
            icon: 'fa-file-video-o',
            color: '#F56565',
          }

        case 'presentation':
          return {
            icon: 'fa-file-powerpoint-o',
            color: '#F6AD55',
          }

        case 'text':
          return {
            icon: 'fa-file-text-o',
            color: '#4FD1C5',
          }

        case 'document':
          return {
            icon: 'fa-file-word-o',
            color: '#7F9CF5',
          }

        case 'folder':
          return {
            icon: 'fa-folder',
            color: '#7F9CF5',
          }

        default:
          return {
            icon: 'fa-file-o',
            color: '#7F9CF5',
          }
      }
    },
    handleClickMediaKit (param) {
      if (!param) {
        return
      }

      return window.open(param)
    },
  },
}
</script>

<style scoped>
.image {
  border-bottom: 1px solid #eaeaea;
  padding: 1rem;
  position: relative;
  font-size: 100px;
}
</style>
