<script setup>
const banners = [
  {
    url: '/purchase/catalogue/771',
    image: 'https://ik.imagekit.io/powerbiz/BHS/Banners/category-banner-1.jpg',
    title: 'Baju Muslim',
  },
  {
    url: '/purchase/catalogue/702',
    image: 'https://ik.imagekit.io/powerbiz/BHS/Banners/category-banner-2.jpg',
    title: 'Songkok',
  },
  {
    url: '/purchase/catalogue/707',
    image: 'https://ik.imagekit.io/powerbiz/BHS/Banners/category-banner-3.jpg',
    title: 'Merchandise',
  },
]
</script>

<template>
  <div class="banner-category">
    <RouterLink v-for="banner, index in banners" :key="index" :to="banner.url">
      <img
        :src="banner.image"
        :alt="banner.title"
      />
      <div class="overlay-content">
        <div class="overlay-button">
          Lihat Kategori
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<style lang="scss" scoped>
.banner-category {
  display: grid;
  grid-gap: .75rem;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-template-rows: repeat(2,minmax(0,1fr));
    grid-gap: .5rem;
  }

  @include media-breakpoint-up(lg) {
    grid-gap: 1rem;
  }

  img {
    max-width: 100%;
    height: 100%;
  }

  > * {
    position: relative;

    .overlay-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 1rem;
      // background-color: rgba(255, 255, 255, .2);

      @include media-breakpoint-up(md) {
        padding: 1.5rem;
      }

      .overlay-button {
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.8);
        padding: .5rem 1.5rem;
        letter-spacing: 1.5px;
        font-weight: 600;
        font-size: .75rem;

        @include media-breakpoint-up(md) {
          font-size: .9rem;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 3;
      }

      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }

      &:nth-child(3) {
        grid-area: 3 / 3 / 2 / 5;
      }
    }
  }
}
</style>
